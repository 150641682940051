module.exports = {
    "development": {
        // "localhost:8080": {
        //     url: "http://dev.api.qarts.partheas.net"
        "dev.kiosk.partheas.dev.qarts.eu": {
            url: "https://api.partheas.dev.qarts.eu"
        }
    },
    "production": {
        "kiosk.mediport.dev.qarts.eu": {
            url: "https://api.mediport.dev.qarts.eu"
        },
        "kiosk.mediris.dev.qarts.eu": {
            url: "https://api.mediris.dev.qarts.eu"
        },
        "kiosk.ubecare.dev.qarts.eu": {
            url: "https://api.ubecare.dev.qarts.eu"
        },
        "kiosk.partheas.dev.qarts.eu": {
            url: "https://api.partheas.dev.qarts.eu"
        },
        "kiosk.cdg.dev.qarts.eu": {
            url: "https://api.cdg.dev.qarts.eu"
        },
        "kiosk.ocs.dev.qarts.eu": {
            url: "https://api.ocs.dev.qarts.eu"
        },
        "kiosk.medicis.dev.qarts.eu": {
            url: "https://api.medicis.dev.qarts.eu"
        },
        "kiosk.beukenlaan.dev.qarts.eu": {
            url: "https://api.beukenlaan.dev.qarts.eu"
        },
        "kiosk.healthhub.dev.qarts.eu": {
            url: "https://api.healthhub.dev.qarts.eu"
        },
        "kiosk.doclr.dev.qarts.eu": {
            url: "https://api.doclr.dev.qarts.eu"
        },
        "kiosk.devaart.dev.qarts.eu": {
            url: "https://api.devaart.dev.qarts.eu"
        },
        "PRODUCTION.TENANT_L": {
            url: "PRODUCTION.TENANT_L_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_M": {
            url: "PRODUCTION.TENANT_M_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_N": {
            url: "PRODUCTION.TENANT_N_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_O": {
            url: "PRODUCTION.TENANT_O_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_P": {
            url: "PRODUCTION.TENANT_P_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_Q": {
            url: "PRODUCTION.TENANT_Q_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_R": {
            url: "PRODUCTION.TENANT_R_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_S": {
            url: "PRODUCTION.TENANT_S_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_T": {
            url: "PRODUCTION.TENANT_T_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_U": {
            url: "PRODUCTION.TENANT_U_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_V": {
            url: "PRODUCTION.TENANT_V_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_W": {
            url: "PRODUCTION.TENANT_W_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_X": {
            url: "PRODUCTION.TENANT_X_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_Y": {
            url: "PRODUCTION.TENANT_Y_API_REDIRECT_URL"
        },
        "PRODUCTION.TENANT_Z": {
            url: "PRODUCTION.TENANT_Z_API_REDIRECT_URL"
        }
    }
}